import React from 'react'
import TransitionLink from 'gatsby-plugin-transition-link'

const PageLink = (props) => (
  <TransitionLink
    entry={{ delay: 0.2 }}
    exit={{ length: 1 }}
    className={props.className}
    activeClassName='active'
    onClick={props.onClick}
    to={props.to}>
    {props.children}
  </TransitionLink>
)

export default PageLink