import React from 'react'
import PropTypes from 'prop-types'
import { Provider, connect } from '@picostate/react'
import { graphql, StaticQuery } from 'gatsby'
import cx from 'classnames'

import Header from '@/header.js'
import ProjectNav from '@/misc/projectNav.js'
import '../styles/main.css'
import store from '../state/store.js'
import cookie from 'js-cookie'

const closeNavs = () => {
  store.hydrate({
    projectNav: false,
    navOpen: false
  })()
  const html = document.querySelector('html')
  html.classList.remove('opened')
}

const LayoutInner = connect(state => ({
  background: state.backgroundColor,
  projectNav: state.projectNav,
  navOpen: state.navOpen
}))((props) => (
  <div className={cx('a-color site', {
    'bcb cw': props.background === false,
    'bcw cb': props.background
  })}>
    <div onClick={closeNavs} className={cx('site__overlay fix x y top left', {
      'is-active': props.projectNav || props.navOpen
    })} />
    {props.children}
  </div>
))

class Layout extends React.Component {
  componentDidMount() {
    window.addEventListener('resize', () => {
      if (store.state.navOpen) {
        store.hydrate({
          navOpen: false
        })()
      }
      if (store.state.projectNav) {
        store.hydrate({
          projectNav: false
        })()
      }
    })
    console.groupCollapsed('Site credits 🍝')
    console.log('Development by Kevin Green https://ctrlaltdel.world')
    console.groupEnd()
  }
  render() {
    return (
      <StaticQuery
        query={graphql`
          query {
            sanityWork {
              id
              internal {
                type
                content
                contentDigest
                owner
              }
            }
          }
        `}
        render={data => {
          const workData = JSON.parse(data.sanityWork.internal.content)
          const projects = workData.selectWork
          return (
            <Provider store={store}>
              <LayoutInner>
                <Header projects={projects} />
                <ProjectNav projects={projects} />
                <main>{this.props.children}</main>
              </LayoutInner>
            </Provider>
          )
        }}
      />
    )
  }
}


Layout.propTypes = {
  children: PropTypes.node.isRequired
}

export default Layout
