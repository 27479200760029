import cookie from 'js-cookie'
import shop from './shopify.js'
import store from '../state/store.js'

export function create() {
  return shop.checkout.create().then(checkout => {
    const checkoutId = checkout.id

    cookie.set('ga_checkout', checkoutId, { expires: 25 })

    store.hydrate({
      checkoutId,
      checkout
    })

    return checkout
  })
}

export function hydrate() {
  const checkoutId = cookie.get('ga_checkout')
  if (checkoutId) {
    store.hydrate({ checkoutId })
    return shop.checkout.fetch(checkoutId).then(checkout => {
      let quantity = 0
      if (!checkout.completedAt) {
        checkout.lineItems.forEach((item, i) => {
          quantity += item.quantity
          checkout.lineItems[i].smallImage = shop.image.helpers.imageForSize(item.variant.image, { maxWidth: 300, maxHeight: 300 })
        })
      }
      if (checkout) {
        return checkout.completedAt ? create() : store.hydrate({ checkout, quantity })()
      } else {
        create()
      }
    })
  } else {
    return create()
  }
}

export function add(items) {
  items = [].concat(items)

  return shop.checkout.addLineItems(store.state.checkoutId, items)
    .then(checkout => {
      let quantity = 0
      checkout.lineItems.forEach((item, i) => {
        quantity += item.quantity
        checkout.lineItems[i].smallImage = shop.image.helpers.imageForSize(item.variant.image, { maxWidth: 300, maxHeight: 300 })
      })
      store.hydrate({ checkout, quantity, cartIsOpen: true })()
    })
}

export function remove(ids) {
  ids = [].concat(ids)

  return shop.checkout.removeLineItems(store.state.checkoutId, ids)
    .then(checkout => {
      let quantity = 0
      checkout.lineItems.forEach((item, i) => {
        quantity += item.quantity
        checkout.lineItems[i].smallImage = shop.image.helpers.imageForSize(item.variant.image, { maxWidth: 300, maxHeight: 300 })
      })
      store.hydrate({ checkout, quantity })()
    })
}

export function update(items) {
  items = [].concat(items)

  return shop.checkout.updateLineItems(store.state.checkoutId, items)
    .then(checkout => {
      let quantity = 0
      checkout.lineItems.forEach((item, i) => {
        quantity += item.quantity
        checkout.lineItems[i].smallImage = shop.image.helpers.imageForSize(item.variant.image, { maxWidth: 300, maxHeight: 300 })
      })
      store.hydrate({ checkout, quantity })()
    })
}
