/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it

const checkout = require('./src/api/checkout.js')

exports.onInitialClientRender = () => {
  // Hydrate the checkout on client entry
  checkout.hydrate()
}

exports.onRouteUpdate = ({ location }) => {
  const html = document.querySelector('html')

if (typeof window !== 'undefined') {
    if(window.location.pathname === "/officehours/") {
      html.classList.add('officeHoursColor')
    }
    else { html.classList.remove('officeHoursColor') }
}
}
