import React from 'react'
import cx from 'classnames'
import { connect } from '@picostate/react'

import store from '../../state/store.js'
import PageLink from '@/link.js'
import { Close } from '@/svgs.js'

const updateStates = () => {
  store.hydrate({
    navOpen: false
  })()
}

const MobileNav = (props) => {
  const {
    navOpen,
    projects
  } = props
  return (
  <div className={cx('header__mobile bcw cb right fix top z5 y', {
    'is-open': navOpen
  })}>
    <div className='header__mobile-inner p15 mt1'>
      <div onClick={updateStates} className='work__nav-close'>
        <Close />
      </div>
      <div className='header__mobile-main mt15'>
        <PageLink to='/work' onClick={updateStates} className='s20 block'>Work</PageLink>
        <PageLink to='/contact' onClick={updateStates} className='s20 block'>Contact</PageLink>
        <PageLink to='/about' onClick={updateStates} className='s20 block'>About</PageLink>
        {/* <PageLink to='/shop' onClick={updateStates} className='s20 block'>Shop</PageLink> */}
        {props.quantity >= 1 && (<PageLink className='s20 block' to='/checkout' onClick={updateStates} >Bag({props.quantity})</PageLink>)}
      </div>
      <div className='mt05 header__mboile-work'>
        {projects.map(project => (
          <div key={project._rev}>
            <PageLink to={`/projects/${project.slug.current}`} onClick={updateStates} className='s20'>{project.menuTitle ? project.menuTitle : project.clientName }</PageLink>
          </div>
        ))}
      </div>
    </div>
  </div>
)}

const mapStateToProps = state => ({
  navOpen: state.navOpen,
})

export default connect(mapStateToProps)(MobileNav)
