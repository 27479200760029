import React from 'react'
import cx from 'classnames'
import { connect } from '@picostate/react'

import Image from '@/image.js'
import PageLink from '@/link.js'
import { MainLogo, Close } from '@/svgs.js'
import store from '../../state/store.js'

const updateStates = () => {
  store.hydrate({
    projectNav: !store.state.projectNav
  })()
  const html = document.querySelector('html')
  html.classList.remove('opened')
}

class ProjectNav extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      hover: null
    }
  }
  stateUpdate () {
    this.setState({
      hover: null
    })
    updateStates()
  }



  render () {
    const {
      projectNav,
      projects
    } = this.props
    return (
      <React.Fragment>
        <div className={cx('work__nav y left top z5 bcw cb fix', {
          'is-open': projectNav
        })}>
          <div className='p15 work__nav-inner'>
          <PageLink to='/' className='navLogo'>
            <MainLogo />
          </PageLink>
            <div onClick={updateStates} className='work__nav-close'>
              <Close /><h4 className='p0 m0 mono h5 caps closeTitle'>Projects</h4>
            </div>
            <div className='mt15 work__nav-nested'>
              {projects.map(project => (
                <div key={project._rev} onMouseEnter={() => this.setState({ hover: project._rev })} onMouseLeave={() => this.setState({ hover: null })}>
                  <PageLink to={`/projects/${project.slug.current}`} onClick={() => this.stateUpdate()} className='s20'>{project.menuTitle ? project.menuTitle : project.clientName }</PageLink>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className={cx('work__information cw z5 y', {
          'is-visible': this.state.hover !== null
        })}>
          {projects.map((project, i) => (
            <div className={cx('work__information-hover abs x y top left f jcs z8 aic', {
              'is-visible': this.state.hover === project._rev
            })} key={project._rev + i}>
              <PageLink to={`/projects/${project.slug.current}`} onClick={updateStates} className='s18 abs x y top left z2' />
              <div className='x work__information-image'>
                <Image width={1000} imageId={project.imageId} source={project.image} />
                <div className='work__information-extra mt1 d6 animate f jcb ais'>
                  <h3 className='m0 s20 pt0'>{project.title}</h3>
                  <p className=' m0 s20 pt0'>{project.description}</p>
                </div>
              </div>

            </div>
          ))}
        </div>
      </React.Fragment>
    )
  }
}


const mapStateToProps = state => ({
  projectNav: state.projectNav,
})

export default connect(mapStateToProps)(ProjectNav)
