import React from 'react'
import { connect } from '@picostate/react'
import PageLink from '@/link.js'
import Helmet from 'react-helmet'
import cookie from 'js-cookie'

import store from '../state/store.js'
import { MainLogo, MoonTime } from '@/svgs.js'
import MobileNav from '@/misc/mobileNav.js'

const updateStates = () => {

  const bgCookieState = store.state.backgroundColor
  cookie.set('ga_color_2022', bgCookieState, { expires: 365 })

  store.hydrate({
    backgroundColor: !store.state.backgroundColor
  })()

  const html = document.querySelector('html')
  const bgcheck = cookie.get('ga_color_2022')

  if (!store.state.backgroundColor) {
    html.classList.add('bcb')
    html.classList.remove('bcw')
  } else {
    html.classList.add('bcw')
    html.classList.remove('bcb')
  }
}
const checkBgState = () => {
  const bgcheck = cookie.get('ga_color_2022')
  if (bgcheck == 'false') {
      updateStates()
  }
}

checkBgState()


const toggleNavigation = () => {
  store.hydrate({
    navOpen: !store.state.navOpen
  })()
}

const Header = connect(state => ({
  background: state.backgroundColor,
  projectNav: state.projectNav,
  navOpen: state.navOpen,
  cart: state.checkout,
  quantity: state.quantity
}))((props) => (
  <React.Fragment>
    <header className='header'>
      <Helmet title='GrandArmy'>
        <link rel="stylesheet" href="/plyr/plyr.css" />
      </Helmet>
      <div className='x fix z4 top left header__inner'>
        <div className='x p1 f jcb ais container--xl ma'>
          <div className='header__logo rel'>
            <PageLink to='/' className=''>
              <MainLogo />{props.background}
            </PageLink>
          </div>
          <div className='header__navigation f jcb aic'>
            <PageLink to='/work' className='mono h5 caps'>Work</PageLink>
            <PageLink to='/contact' className='ml05 mono h5 caps'>Contact</PageLink>
            <PageLink to='/about' className='ml05 mono h5 caps'>About</PageLink>
            {props.quantity >= 1 && (<PageLink className='ml05 mono h5 caps' to='/checkout'>Bag({props.quantity})</PageLink>)}
            <div className='header__color fix right ml05' onClick={updateStates}>
              <MoonTime />
            </div>
          </div>
          <div className='header__toggle f jcc aic' onClick={toggleNavigation}>
            <div className='header__toggle-lines rel' />
          </div>
        </div>
      </div>
    </header>
    <MobileNav projects={props.projects} quantity={props.quantity} />
  </React.Fragment>
))

export default Header
